.error-container{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    padding: 0;
    margin: 0;
    color: $primary;
    font-size: 1.3em;
    text-transform: uppercase;
    text-align: center;

  }
}
