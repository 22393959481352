// Colors
$primary: #70184d;
$primary-2: #183058;
$secondary: #aeb3cb;
$secondary-2: #db6382;
$white: #fff;
$black: #000;
$gray: #3f3f3f;
$tertiary: #EBE5D6;

$border-1: #CBCBCB;
$bg-app : #FCFAF7;
//Font Family
$font-family-1: "Libre Franklin", sans-serif;
$font-family-2: "Merriweather", serif;
$font-family-3: "Kiona",sans-serif;
$font-family-4: "Lyon Display Web", sans-serif;
$breakpoint-sm: 768px;
$width-container: 1280px;
$width-container-app:  830px;
