.container-form-application {
  width: 100%;
  margin: 0 auto;
  padding: 2.3rem;
  font-family: $font-family-4;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-height: 90vh;
  overflow: auto;
  background-color: #fff;
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4);
    opacity: 1;
    border-radius: 24px;
    &:hover {
      background-color: rgba($primary, 0.9);
    }
  }
  @media screen and (max-width: $breakpoint-sm) {
    padding: calc(2vw + .5rem)
  }



  .col {
    &, &-1, &-2, &-3, &-4, &-5, &-6, &-7, &-8, &-9, &-10, &-11, &-12 {
      padding-left: 0;
    }
  }

  .title-form {
    font-family: $font-family-3;
    font-weight: 700;
    color: $primary-2;
    font-size: 1em;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    letter-spacing: 0.11em;
    line-height: 2.25em;
    border-bottom: 1px solid rgba(201, 206, 214, 0.65);
  }

  .fragment-form-application {

    &.inactive {
      padding: .3rem;
    }
  }
}

.paginate-steps {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  .step {
    flex-basis: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    span {
      overflow: hidden;
      width: 80%;
      text-align: center;
      font-size: 0.75em;
      font-family: $font-family-3;
      text-transform: uppercase;
      height: 4rem;
      font-weight: 700;
      color: $primary-2;
    }

    .container-value {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

    }

    .container-value {
      &:after {
        z-index: -2;
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 100%;
        opacity: .3;
        height: 2px;
        background-color: $primary-2;
      }
    }

    &:last-child {
      .container-value {
        &:after {
          width: 50%;
        }
      }
    }

    &:first-child {
      .container-value {
        &:after {
          left: initial;
          right: 0;
          width: 50%;
        }
      }
    }


    .container-icon {
      background-color: inherit;
    }

    .icon {
      display: flex;
      font-family: $font-family-4;
      align-items: center;
      justify-content: center;
      color: $white;
      font-weight: 700;
      font-size: 0.75em;
      border-radius: 50%;
      box-sizing: border-box;
      position: relative;
      padding: .5rem;
      width: 1.5rem;
      height: 1.5rem;
      background-color: rgba($secondary-2, 0.3);
      transition: background-color .3s ease-in-out;

      &.active {
        background-color: $secondary-2;
      }

      &:after {
        content: "";
        position: absolute;
        z-index: -1;
        width: 130%;
        height: 130%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $bg-app;
      }
    }
    @media screen and (max-width: 768px) {
      flex-direction: row;
      flex-basis: initial;
      margin: 0 .5rem;
      span {
        text-align: left;
        height: auto;
        width: 100%;
        padding: 0 .3rem;
      }
      .icon {
        &:after {
      display: none;
        }
      }

    }
  }

}

.wrapper-forms {
  overflow: hidden;

  .scope-form {
    display: flex;
    transition: all .3s ease-in-out;
  }

  .fragment-form-application {
    flex-shrink: 0;
    width: 100%;
  }
}
.container-text {
  padding: .5rem 0;
  font-size: 1em;
  font-weight: 400;
  font-family: $font-family-4;
  line-height: 1.5em;
  color: $primary-2;
  p {
    margin: 0;
    padding: 1rem 0;
  }
}
.introductory-div {
  p{
    padding: .5rem 0;
    font-size: 1em;
    font-weight: 400;
    font-family: $font-family-4;
    line-height: 1.5em;
    color: $primary-2;
  }
}
.complete-form-view{
  min-height: 100vh;
  display:flex;
  align-items:center;
  .container-complete {
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;

    .icon {
      width: 4rem;
      height: 4rem;
      border-radius:50%;
      background-color: $primary;
      display:flex;
      padding: 1rem;
      align-items: center;
      justify-content:center;
      margin: .6rem;
      img{
        width:100%;
      }
    }
    .title {
      font-size: 2em;
      font-weight: 600;
    }
    .paragraph {
      font-family: $font-family-4;
      color: $primary-2;
      padding: 0;
    }
  }
}

.footer_col {
  flex-basis: 25%;
  @media screen and (max-width: 768px){
    flex-basis: 100%;
  }
}

