body,html {
  margin: 0;
  padding: 0;
  color: #000000;
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-family: $font-family-3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &,*, *:after , *:before {
    box-sizing: border-box;
  }
  font-size: 1rem;

  @media screen and (max-width: $breakpoint-sm) {
    font-size: .9rem;
  }
  .view-application{
    background-color: $bg-app;
  }
}
