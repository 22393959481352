.page-footer {

  width: 100%;
  color: #FFFFFF;
  margin-top: 2rem;
}

.footer_bottom_holder {
  font-family: $font-family-4;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 24px;
  font-weight: 400;
  text-transform: none;
  font-style: normal;

  .footer_bottom {
    padding-top: 5px;
  }

  .footer_bottom {

    font-size: 14px;
    line-height: 22px;
    margin: 0 auto;
    max-width: $width-container;
    width: 82%;
    padding: .5rem;
    vertical-align: middle;
  }

  .footer_bottom, .side_menu.side_area_alignment_center {
    text-align: center;
  }

  .disclaimer-footer {
    margin-top: 1.8rem;
  }  
  .disclaimer-footer p {
    text-align: justify;
    margin-bottom: 5px !important;
    color: #8694ab !important;
    font-size: 11px !important;
    line-height: 1.5em !important;
  }

}
.footer_bottom_border {
  background-color: #FAFAFA;
  height: 1px;
}

.footer_top,
.footer_top.footer_top_full {
  padding-top: 20.625rem;
  padding-bottom: 90px;
  @media screen and (max-width:768px) {
    padding-top: 26rem;
  }
}
.footer_top .container {
  max-width: $width-container;
}
.footer_top .column_inner > div h2,
.footer_top .column_inner > div h3,
.footer_top .column_inner > div h4,
.footer_top .column_inner > div h5,
.footer_top .column_inner > div h6 {
  color: #ffffff;
}

.footer_top,
.footer_top p,
.footer_top span,
.footer_top li,
.footer_top .textwidget,
.footer_top .widget_recent_entries > ul > li > span {
  color: #ffffff;
}

.footer_top a:not(.qbutton) {
  color: #ffffff !important;
}

.footer_top .q_social_icon_holder .simple_social {
  color: #ffffff;
}

.footer_top a:not(.qbutton):hover {
  color: #db6382 !important;
}

.footer_top .q_social_icon_holder:hover .simple_social {
  color: #db6382;
}

.footer_top_holder {
  background-size: cover;
  background-image: url("./../Assets/backgrounds/footer.png");
  background-repeat: no-repeat;
}

.footer_bottom_holder, #lang_sel_footer {
  background-color: #183058;
}


.footer_bottom_holder {
  padding-bottom: 7px
}

.footer_bottom {
  padding-top: 5px;
}


.footer_top h5 {
 font-family: $font-family-3;
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 30px;
  font-weight: 600;
  text-transform: none;
  color: #ffffff;
  font-style: normal
}

.footer_top,
.footer_top p,
.footer_top span:not(.q_social_icon_holder):not(.fa-stack):not(.qode_icon_shortcode):not(.qode_icon_font_elegant):not(.qode-icon-kiko),
.footer_top li,
.footer_top .textwidget,
.footer_top .widget_recent_entries > ul > li > span {
  font-family: $font-family-4;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 26px;
  font-weight: 400;
  text-transform: none;
  font-style: normal
}

.footer_top a:not(.qbutton) {
  font-family: $font-family-4;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 26px;
  font-weight: 400;
  text-transform: none;
  font-style: normal
}

.footer_top,
.footer_top .footer_col1,
.footer_top .container_inner > .widget,
.footer_top.footer_top_full > .widget {
  text-align: left;
}

.footer_top .footer_col2 {
  text-align: left;
}

.footer_top .footer_col3 {
  text-align: left;
}

.footer_top .footer_col4 {
  text-align: left;
}
a {
  text-decoration: none;
  color: $secondary-2;
  transition: all .3s ease-in-out;

  &:hover, &:active, &:visited {
    color: $secondary-2;
    text-decoration: none;
  }
}


.clearfix:after, .clearfix:before {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
.clearfix:after, .clearfix:before {
  content: " ";
  display: table;
}

.footer_col {
  flex-basis: 22% !important;
  #footer-logo {
    margin-top: -10px;
  }
  #b-certified {
    margin-top: 1rem;
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 768px){
    flex-basis: 100% !important;
    #footer-logo {
      width: 233px; 
      height: 46px;
      margin-top: 0px;
    }
    #b-certified {
      margin-top: 1rem;
      width: 215px;
      height: auto;
    }
  }
}
.textwidget a {
   color: #ffffff;
}

.columns-box {
  margin: 0 auto;
  width: 82%;
  justify-content: space-between;
}

.footer-social-media {
  width: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  img {
    background-image: radial-gradient($primary 0%, $primary 62%, transparent 20%);
  }
  a {
    margin-right: .8rem;
    padding: .2rem 0; 
  }
  @media screen and (max-width: 1395px) {
    a:last-of-type {
      margin-left: 1.6rem;
    }
  }
  @media screen and (max-width: 1022px) {
    a:last-of-type {
      margin-left: 0;
    }
  }
  @media screen and (max-width: 768px){
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    a {
      margin-right: 0;
      padding: .2rem .8rem 0 0; 
    }
    a:last-of-type {
      margin-left: 0;
    }
  }
}

#footer-column-title {
  font-family: 'Kiona-Regular', sans-serif;
  font-weight: 700;
}

