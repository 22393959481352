@font-face {
  font-family: 'Lyon Display Web';
  src: url('LyonDisplay-BlackItalic-Web.woff2') format('woff2'),
  url('LyonDisplay-BlackItalic-Web.woff') format('woff');
  font-weight:  900;
  font-style:   italic;
  font-stretch: normal;
}

.LyonDisplay-BlackItalic-Web {
  font-family: 'Lyon Display Web';
  font-weight:  900;
  font-style:   italic;
  font-stretch: normal;
}


@font-face {
  font-family: 'Lyon Display Web';
  src: url('LyonDisplay-Black-Web.woff2') format('woff2'),
  url('LyonDisplay-Black-Web.woff') format('woff');
  font-weight:  900;
  font-style:   normal;
  font-stretch: normal;
}

.LyonDisplay-Black-Web {
  font-family: 'Lyon Display Web';
  font-weight:  900;
  font-style:   normal;
  font-stretch: normal;
}


@font-face {
  font-family: 'Lyon Display Web';
  src: url('LyonDisplay-BoldItalic-Web.woff2') format('woff2'),
  url('LyonDisplay-BoldItalic-Web.woff') format('woff');
  font-weight:  700;
  font-style:   italic;
  font-stretch: normal;
}

.LyonDisplay-BoldItalic-Web {
  font-family: 'Lyon Display Web';
  font-weight:  700;
  font-style:   italic;
  font-stretch: normal;
}


@font-face {
  font-family: 'Lyon Display Web';
  src: url('LyonDisplay-Bold-Web.woff2') format('woff2'),
  url('LyonDisplay-Bold-Web.woff') format('woff');
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
}

.LyonDisplay-Bold-Web {
  font-family: 'Lyon Display Web';
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
}


@font-face {
  font-family: 'Lyon Display Web';
  src: url('LyonDisplay-MediumItalic-Web.woff2') format('woff2'),
  url('LyonDisplay-MediumItalic-Web.woff') format('woff');
  font-weight:  500;
  font-style:   italic;
  font-stretch: normal;
}

.LyonDisplay-MediumItalic-Web {
  font-family: 'Lyon Display Web';
  font-weight:  500;
  font-style:   italic;
  font-stretch: normal;
}


@font-face {
  font-family: 'Lyon Display Web';
  src: url('LyonDisplay-Medium-Web.woff2') format('woff2'),
  url('LyonDisplay-Medium-Web.woff') format('woff');
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
}

.LyonDisplay-Medium-Web {
  font-family: 'Lyon Display Web';
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
}


@font-face {
  font-family: 'Lyon Display Web';
  src: url('LyonDisplay-RegularItalic-Web.woff2') format('woff2'),
  url('LyonDisplay-RegularItalic-Web.woff') format('woff');
  font-weight:  400;
  font-style:   italic;
  font-stretch: normal;
}

.LyonDisplay-RegularItalic-Web {
  font-family: 'Lyon Display Web';
  font-weight:  400;
  font-style:   italic;
  font-stretch: normal;
}


@font-face {
  font-family: 'Lyon Display Web';
  src: url('LyonDisplay-Regular-Web.woff2') format('woff2'),
  url('LyonDisplay-Regular-Web.woff') format('woff');
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}

.LyonDisplay-Regular-Web {
  font-family: 'Lyon Display Web';
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}


@font-face {
  font-family: 'Lyon Display Web';
  src: url('LyonDisplay-LightItalic-Web.woff2') format('woff2'),
  url('LyonDisplay-LightItalic-Web.woff') format('woff');
  font-weight:  300;
  font-style:   italic;
  font-stretch: normal;
}

.LyonDisplay-LightItalic-Web {
  font-family: 'Lyon Display Web';
  font-weight:  300;
  font-style:   italic;
  font-stretch: normal;
}


@font-face {
  font-family: 'Lyon Display Web';
  src: url('LyonDisplay-Light-Web.woff2') format('woff2'),
  url('LyonDisplay-Light-Web.woff') format('woff');
  font-weight:  300;
  font-style:   normal;
  font-stretch: normal;
}

.LyonDisplay-Light-Web {
  font-family: 'Lyon Display Web';
  font-weight:  300;
  font-style:   normal;
  font-stretch: normal;
}

