/*
Font: 		Kiona
Style: 		SemiBold Itallic
URL: 		https://www.youworkforthem.com/font/T9000/kiona
Foundry: 	Ellen Luff
Foundry: 	https://www.youworkforthem.com/designer/1024/ellen-luff
Copyright:	Ellen Luff
Version: 	17
Created:	March 23, 2018
License: 	https://www.youworkforthem.com/font-license
License: 	The WebFont(s) listed in this document must follow the YouWorkForThem
			WebFont license rules. All other parties are strictly restricted
			from using the WebFonts(s) listed without a purchased license.
			All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
	font-family: 'Kiona-SemiBoldItallic';
	src: url('kiona-semibold-itallic.eot');
	src: url('kiona-semibold-itallic.eot?#iefix') format('embedded-opentype'),
             url('kiona-semibold-itallic.woff2') format('woff2'),
	     url('kiona-semibold-itallic.woff') format('woff'),
	     url('kiona-semibold-itallic.ttf') format('truetype'),
	     url('kiona-semibold-itallic.svg#youworkforthem') format('svg');
	font-weight: normal;
	font-style: normal;
}
