
.ERH-btn {
  font-size: 1em;
  font-weight: 600;
  padding: 0.75rem 0;
  text-align: center;
  width: 11.8125rem;
  max-width: 100%;
  border: solid 1px $primary;
  font-family: $font-family-3;
  line-height: 1.5em;
  cursor: pointer;
  transition: all .3s ease-in-out;

  &:hover {
    opacity: .8;
  }
  &:active,&:focus {
    opacity: 1;
    outline: none;
  }
  &.round {
    border-radius: 500px;
  }
   &:disabled {
     opacity: .6;
   }
  &.outline {
    color: $primary;
    background-color: transparent;
  }

  &:not(.outline) {
    background-color: $primary;
    color: $white;
  }
  @media screen and (max-width: $breakpoint-sm) {
    width: 100% !important;
  }
}

.remove-btn {
  font-weight: 600;
  font-size: .8rem;
  font-family: $font-family-3;
  text-align: center;
  width: 5.8125rem;
  max-width: 100%;
  border: solid 1px $primary;
  border-radius: .3rem;
  line-height: 1.5em;
  cursor: pointer;
  transition: all .3s ease-in-out;
  padding: .35rem .5rem;

  &:hover {
    opacity: .8;
  }
  &:active,&:focus {
    opacity: 1;
    outline: none;
  }
  &.round {
    border-radius: 500px;
  }
  &:disabled {
    opacity: .6;
  }
  &.outline {
    color: $primary;
    background-color: transparent;
  }

  &:not(.outline) {
    background-color: $primary;
    color: $white;
  }
  @media screen and (max-width: $breakpoint-sm) {
    width: 100% !important;
  }
}
